import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import MobileNav from './ui/mobileNav'

interface LayoutProps {
   children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
   return (
      <>
         <Navbar />
         <main>
            {children}
         </main>
         <Footer />
         <MobileNav />
      </>
   )
}

export default Layout