import { user1, user4 } from "../images"


export const commets = [
   {
      id: 1,
      avatar: user1,
      name: 'Paulo Alvez',
      comemt: 'Os joelhos da abelha arrancam pedaços e pedaços do seu braço, ele o cortou, meu Deus, companheiro eviscerado caramba, velho fora de sua noz argy bargy vagabundo sodomizado caiu.',
      postedAt: new Date()
   },
   {
      id: 1,
      avatar: user4,
      name: 'Hanna Maria',
      comemt: 'Os joelhos da abelha arrancam pedaços e pedaços do seu braço, ele o cortou, meu Deus, companheiro eviscerado caramba, velho fora de sua noz argy bargy vagabundo sodomizado caiu.',
      postedAt: new Date()
   },
]


export const categories = [
   { lable: 'Todos' },
   { lable: 'Psicologia' },
   { lable: 'Agencia' },
   { lable: 'Parceria' },
   { lable: 'Markiting digital' },
   { lable: 'Gerenciamento de négocíos' },
   { lable: 'Análise de mídia avançada' },
   { lable: 'SEO orgânico de longo prazo' },
   { lable: 'Marketing digital' },
]
export const postion = [
   { lable: 'Todos' },
   { lable: 'CEO' },
   { lable: 'Gerente de Operações' },
   { lable: 'Chefe de Finanças' },
]
export const questions = [
   {
      id: 1,
      title: 'First question',
      response: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae corporis exercitationem quo magnam laborum natus quasi alias dolor omnis cupiditate temporibus cum, facere nam qui? Ullam libero reprehenderit dolore quam.'
   },
   {
      id: 2,
      title: 'Second question',
      response: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae corporis exercitationem quo magnam laborum natus quasi alias dolor omnis cupiditate temporibus cum, facere nam qui? Ullam libero reprehenderit dolore quam.'
   },
   {
      id: 3,
      title: 'treed question',
      response: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae corporis exercitationem quo magnam laborum natus quasi alias dolor omnis cupiditate temporibus cum, facere nam qui? Ullam libero reprehenderit dolore quam.'
   },
   {
      id: 4,
      title: 'fouth question',
      response: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae corporis exercitationem quo magnam laborum natus quasi alias dolor omnis cupiditate temporibus cum, facere nam qui? Ullam libero reprehenderit dolore quam.'
   },
]
