import React from 'react';
import './src/styles/global.css';
import './src/styles/swiper.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { MainProvider } from './src/context/provider';
import RootElement from './src/components/root-elemente';

export const wrapPageElement = ({ element }) => {
  return (
    <MainProvider>
      <RootElement>{element}</RootElement>;
    </MainProvider>
  );
};
