
export const replaceWhitespaceWithDash = (array: string[]) => {
   return array.map(item => item.replace(/[´çõí]/g, match => {
      switch (match) {
         case '´':
            return '';
         case 'ç':
            return 'c';
         case 'õ':
            return 'o';
         case 'í':
            return 'i';
         default:
            return match;
      }
   }).replace(/\s+/g, '-'));
}
export const getCurrentBrowser = () =>  {
   if(typeof navigator !== 'undefined') {
     const userAgent = navigator.userAgent;
   
     if (userAgent.indexOf("Chrome") !== -1) {
       return "Google Chrome";
     } else if (userAgent.indexOf("Firefox") !== -1) {
       return "Mozilla Firefox";
     } else if (userAgent.indexOf("Safari") !== -1) {
       return "Apple Safari";
     } else if (userAgent.indexOf("Edge") !== -1) {
       return "Microsoft Edge";
     } else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
       return "Internet Explorer";
     } else {
       return "Unknown";
     }
     
   }
  }
export const getUserCountry = () => {
   return new Promise((resolve, reject) => {
      if (typeof navigator !== 'undefined' && navigator.geolocation) {
        navigator.permissions.query({ name: "geolocation" }).then(permissionStatus => {
          if (permissionStatus.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              position => {
                const { latitude, longitude } = position.coords;
                fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
                  .then(response => response.json())
                  .then(data => {
                    const country = data.countryName;
                    resolve(country);
                  })
                  .catch(error => {
                    reject(error);
                  });
              },
              error => {
                reject(error);
              }
            );
          } else if (permissionStatus.state === "prompt") {
            reject(new Error("Geolocation permission prompt"));
          } else if (permissionStatus.state === "denied") {
            reject(new Error("Geolocation permission denied"));
          }
        }).catch(error => {
          reject(error);
        });
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
 }