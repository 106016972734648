exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-eventos-[id]-tsx": () => import("./../../../src/pages/eventos/[id].tsx" /* webpackChunkName: "component---src-pages-eventos-[id]-tsx" */),
  "component---src-pages-eventos-index-tsx": () => import("./../../../src/pages/eventos/index.tsx" /* webpackChunkName: "component---src-pages-eventos-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-membros-index-tsx": () => import("./../../../src/pages/membros/index.tsx" /* webpackChunkName: "component---src-pages-membros-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-projectos-[id]-tsx": () => import("./../../../src/pages/projectos/[id].tsx" /* webpackChunkName: "component---src-pages-projectos-[id]-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-termos-e-condicoes-tsx": () => import("./../../../src/pages/termos-e-condicoes.tsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-tsx" */)
}

