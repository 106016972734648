import {
   ShoppingBag, SunIcon, Bell, Eye, Heart, LayoutDashboard, Network, MessageCircle, X, AreaChart, ChevronRight,
   Search,
   Home

} from 'lucide-react'

export const Icon = {
   home: Home,
   layout: LayoutDashboard,
   network: Network,
   messageChat: MessageCircle,
   cart: ShoppingBag,
   sun: SunIcon,
   bell: Bell,
   eye: Eye,
   heart: Heart,
   close: X,
   chart: AreaChart,
   search: Search,
   rightArrow: ChevronRight
}